.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .historySwiper {

    height: 37rem;

  }

  .loader {
    width: 90px;
    height: 90px;
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    border: #8B4DBA solid 0.15vw;
    background-image:
      radial-gradient(circle 10px , #8B4DBA 100%, transparent 0),
      radial-gradient(circle 10px , #8B4DBA 100%, transparent 0),
      radial-gradient(circle 10px , #8B4DBA 100%, transparent 0),
      radial-gradient(circle 10px , #8B4DBA 100%, transparent 0),
      radial-gradient(circle 10px , #8B4DBA 100%, transparent 0),
      radial-gradient(circle 10px , #8B4DBA 100%, transparent 0);
      background-repeat: no-repeat;
    animation: move 6s linear infinite , rotate 3s linear infinite;
  }
  
  @keyframes rotate {
    0% , 20%{ transform: rotate(0deg)}
    30% , 40% { transform: rotate(90deg)}
    50% , 60% { transform: rotate(180deg)}
    70% , 80% { transform: rotate(270deg)}
    90%,  100% { transform: rotate(360deg)}
  }
  @keyframes move {
    0% ,  9%{
        background-position:
        -25px -28px,  -25px 0px, -25px 28px,
        25px -28px,  25px 0px,  25px 28px;
    }
    10% , 25%{
        background-position:
        0px -28px,  -25px 0px, -25px 28px,
        57px -25px,  25px 0px,  25px 28px;
    }
    30% , 45%{
        background-position:
        0px -57px, -25px -23px, -25px 25px,
        57px -28px, 25px -23px, 25px 25px;
    }
    50% , 65% {
        background-position:
        0px -57px, -325px -57px, -25px 25px,
        57px 25px, 0px -23px, 25px 25px;
    }
    70% , 85% {
        background-position:
        0px -57px, -25px -57px, 0px 25px,
        57px -25px, 0px -23px, 57px 25px;
    }
   90% , 100% {
        background-position:
        0px -57px, -30px -57px, 0px 0px,
        57px -30px, 0px 0px, 57px 30px;
    }
  }